import React, { FC, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import classnames from 'classnames';

import DangerouslySetInnerHtml from 'gatsby-theme-husky/src/common/DangerouslySetInnerHtml';

import { IRteSectionProps } from 'gatsby-theme-husky/src/components/RteSection/model';

import PromoFormComponent from '@promo-platform/promo-form';

import { FORM_SECTION_THEME, NBC_PRIVACY, NBC_PRIVACY_LINK, NEW_TAB, SUCCESS_MESSAGE, TOP_CHEF_PP_FORM_ID, TOP_CHEF_PP_FORM_URL, TOP_CHEF_SWEEPS_RULE, TOP_CHEF_SWEEPS_RULE_LINK } from './constants';

import 'gatsby-theme-husky/src/components/RteSection/RteSection.scss';
import './RteSectionOverride.scss';


const RteSection: FC<IRteSectionProps> = ({ text, sectionTheme }) => {
    const [formSubmitted, SetFormSubmitted] = useState(false);

    useEffect(() => {
        if(sectionTheme == FORM_SECTION_THEME) {
            document.querySelector(".footer-top__promo-form")?.classList.add("hide-form");
        }
        if (formSubmitted) {
            document.querySelector(".rte-section--equity-signup-form .signup-form")?.classList.add("d-none");
        }
    }, [formSubmitted]);

    return (
        <section
            className={classnames('rte-section', {
                [`rte-section--${sectionTheme}`]: sectionTheme,
            })}
            id={sectionTheme || ''}
        >
            <div className="rte-section__container">
                <DangerouslySetInnerHtml className="rte-section__text" html={text} />
                {sectionTheme == FORM_SECTION_THEME ?
                    <div className='equity-form'>
                        {formSubmitted ?
                            <div className="pp-form__container success">{SUCCESS_MESSAGE}</div>
                            :
                            <PromoFormComponent id={TOP_CHEF_PP_FORM_ID} url={TOP_CHEF_PP_FORM_URL} onSuccessCallback={() => SetFormSubmitted(true)} />
                        }
                        <ul className="rules-link">
                            <li><a href={NBC_PRIVACY_LINK} aria-label={`${NBC_PRIVACY} ${NEW_TAB}`} target='_blank'>{NBC_PRIVACY}</a></li>
                            <li><a href={TOP_CHEF_SWEEPS_RULE_LINK} aria-label={`${TOP_CHEF_SWEEPS_RULE} ${NEW_TAB}`} target='_blank'>{TOP_CHEF_SWEEPS_RULE}</a></li>
                        </ul>
                    </div>
                    : null
                }
            </div>

        </section>
    );
}

export const query = graphql`
  fragment FragmentRteSection on TRteSection {
    properties {
      text
      sectionTheme
    }
    structure
  }
`;

export default RteSection;
