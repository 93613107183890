import React, { FC, useState } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import Button from 'common/Button';
import Icon from 'common/IconCustom';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import GatsbyImage from 'common/GatsbyImage';

import { IRetailersSectionProps } from './model';

import './RetailersSection.scss';

const RetailersSection: FC<IRetailersSectionProps> = ({
  title,
  retailer,
  limit,
  image,
  seeMoreLabel,
  seeLessLabel,
  listTitle,
  sectionTheme,
  link,
}) => {
  const totalRetailers = retailer.length;
  const [retailersToShow, setRetailersToShow] = useState(limit || totalRetailers);

  const toggleLabel = {
    [limit]: seeMoreLabel,
    [totalRetailers]: seeLessLabel,
  };

  const handleToggleRetailerToShow = () => {
    const retailerLimit = retailersToShow === totalRetailers ? limit : totalRetailers;
    setRetailersToShow(retailerLimit);
  };

  return (
    <section
      className={classNames('retailers', {
        [`retailers--${sectionTheme}`]: sectionTheme,
      })}
    >
      <div className="retailers__container">
        <DangerouslySetInnerHtml className="retailers__title" html={title} />

        <div className="retailers__holder">
          {image?.[0]?.properties?.image ? (
            <div className="retailers__image">
              <GatsbyImage
                objectFit="contain"
                image={image[0].properties.image}
                alt={image[0].properties.imageAlt}
              />
            </div>
          ) : null}

          <div className="retailers__list-holder">
            <DangerouslySetInnerHtml className="retailers__list-title" html={listTitle} />

            <ul className="retailers__list">
              {retailer.slice(0, retailersToShow).map(
                ({
                  properties: {
                    image: { logo, ariaLabel, imageAlt },
                    link: retailerLink,
                  },
                }) => {
                  const { url, queryString, target } = retailerLink?.[0] || {};

                  return (
                    <li>
                      <Button
                        key={imageAlt + ariaLabel}
                        to={url + (queryString || '')}
                        target={target}
                        variant="white"
                        ariaLabel={ariaLabel}
                        classes="retailers__list-item-link"
                      >
                        <GatsbyImage
                          image={logo}
                          objectFit="contain"
                          className="retailers__list-item-image"
                          alt={imageAlt}
                        />
                      </Button>
                    </li>
                  );
                }
              )}
            </ul>
            {limit < totalRetailers ? (
              <button
                type="button"
                className="retailers__toggler"
                onClick={handleToggleRetailerToShow}
              >
                {toggleLabel[retailersToShow]}
                <Icon
                  classes="icon__prefix"
                  icon={`chevron-${limit !== retailersToShow ? 'up' : 'down'}`}
                />
              </button>
            ) : null}

            {link?.[0] ? (
              <Button
                to={link[0].url}
                ariaLabel={link[0].name}
                variant="dark-blue"
                iconSuffix="chevron-right"
                target={link[0].target}
                classes="retailers__link"
              >
                {link[0].name}
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment FragmentRetailersSection on TRetailersSection {
    properties {
      title
      image {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 330, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
      retailer {
        properties {
          image {
            logo {
              ...FragmentGatsbyProps
              gatsbyImage {
                childImageSharp {
                  fluid(maxWidth: 210, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            imageAlt
            ariaLabel
          }
          link {
            queryString
            url
            target
            name
          }
        }
      }
      limit
      seeMoreLabel
      seeLessLabel
      listTitle
      link {
        queryString
        url
        target
        name
      }
      sectionTheme
    }
    structure
  }
`;

export default RetailersSection;
